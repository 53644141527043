// COLORS - THEME
$color-primary: #bcd871;
$color-secondary: #97ad5a;
$color-tertiary: #cfef7f;

// COLORS - BLACK
$color-black: #222222;
$color-gray: #707070;

// COLORS - WHITES
$color-white: #ffffff;
$color-background: #fafafa;

$color-fill-dark: #191919;
$color-fill-light: #f7f7f7;

// $color-text-dark: rgb(39, 8, 14);
$color-text-dark: #222222;
$color-text-light: #ffffff;

// STYLE AND LAYOUT ALTERINGS
.bold {
  font-weight: 700;
}
.ctr {
  margin: 0 auto;
}
