.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30rem;
  padding: 2.5rem 3rem;
  margin-top: 7rem;
  background-color: #000000;
  color: rgba($color-white, 0.65);
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // z-index: 1;

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__nav-list {
    list-style: none;
  }

  &__nav-item:not(:last-of-type) {
    margin-bottom: 1.6rem;
  }

  &__social {
    p {
      font-weight: 500;
    }

    .nav__link--white {
      font-size: 2.4rem;
    }
  }

  &__social-icon {
    font-size: 2.4rem;
    padding-right: 1rem;
  }

  &__copyright {
    align-self: center;
    margin: 3.2rem auto;
    font-size: 1.3rem;
  }
}
