h1 {
  font-size: 3.2rem;
  font-weight: 700;
  color: $color-secondary;
}
h2 {
  font-size: 2.7rem;
  font-weight: 500;
  color: $color-secondary;
}
h3 {
  font-size: 2.2rem;
  font-weight: 500;
}
h4 {
  font-size: 1.8rem;
  font-weight: 700;
}
h5 {
  font-size: 1.8rem;
  font-weight: 300;
  text-transform: uppercase;
}
p {
  font-size: 1.8rem;
  font-weight: 400;
}
