@import url("https://use.typekit.net/mfp3taz.css");
@keyframes slowFade {
  5% {
    opacity: 1;
    transform: scale(1) rotate(0);
  }
  100% {
    opacity: 0;
  }
}
@keyframes slideIn {
  from {
    left: 300px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}
@keyframes slideOut {
  from {
    left: 0;
    opacity: 1;
  }
  to {
    left: -500px;
    opacity: 0;
  }
}
.bold {
  font-weight: 700;
}

.ctr {
  margin: 0 auto;
}

/* CSS RESET */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /* 10px / 16px = 62.5% => 1rem = 10px */
}

body {
  font-family: "Rival-Sans", serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.6;
  background-color: #fafafa;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "lato", sans-serif;
  font-style: normal;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.hidden {
  display: none;
}

a {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-size: 3.2rem;
  font-weight: 700;
  color: #97ad5a;
}

h2 {
  font-size: 2.7rem;
  font-weight: 500;
  color: #97ad5a;
}

h3 {
  font-size: 2.2rem;
  font-weight: 500;
}

h4 {
  font-size: 1.8rem;
  font-weight: 700;
}

h5 {
  font-size: 1.8rem;
  font-weight: 300;
  text-transform: uppercase;
}

p {
  font-size: 1.8rem;
  font-weight: 400;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 4.5rem 0 7.5rem 0;
}
.button__btn {
  background-color: #bcd871;
  color: #222222;
  padding: 2rem 5rem;
  border-radius: 0.5rem;
  border: 1px solid #97ad5a;
  box-shadow: 3px 3px 7px rgba(34, 34, 34, 0.15);
  font-size: 2rem;
  font-weight: 400;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  margin-top: 7.5rem;
  padding: 3rem;
  max-width: 100%;
  background-color: #ffffff;
  border-radius: 7px;
  box-shadow: 5px 5px 10px rgba(34, 34, 34, 0.15);
}
.card__image {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-bottom: 5px solid #bcd871;
}
.card__image img {
  width: 100%;
  margin-bottom: 3rem;
}
.card__headline {
  margin: 2rem 0;
}
.card__text-button {
  align-self: center;
  margin-top: 5rem;
  margin-bottom: 2rem;
}
.card__link {
  border-radius: 5px;
  background-color: #ffffff;
  text-decoration: underline;
  color: #97ad5a;
  font-size: 1.8rem;
  transition: all 1s ease;
}
.card__link:hover {
  background-color: #cfef7f;
  color: #97ad5a;
}

.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30rem;
  padding: 2.5rem 3rem;
  margin-top: 7rem;
  background-color: #000000;
  color: rgba(255, 255, 255, 0.65);
}
.footer__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.footer__nav-list {
  list-style: none;
}
.footer__nav-item:not(:last-of-type) {
  margin-bottom: 1.6rem;
}
.footer__social p {
  font-weight: 500;
}
.footer__social .nav__link--white {
  font-size: 2.4rem;
}
.footer__social-icon {
  font-size: 2.4rem;
  padding-right: 1rem;
}
.footer__copyright {
  align-self: center;
  margin: 3.2rem auto;
  font-size: 1.3rem;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 7px;
  padding: 5rem 0;
  margin-top: 7.5rem;
  margin-bottom: 2.5rem;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 7px;
  box-shadow: 5px 5px 10px rgba(34, 34, 34, 0.15);
}
.form__group {
  display: flex;
  flex-direction: row;
  width: 90%;
  position: relative;
}
.form__group > button {
  margin: 5rem auto;
}
.form__group:not(:last-child) {
  margin-bottom: 3.5rem;
}
.form__icon {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 5.5rem;
  height: 100%;
  background-color: #bcd871;
  color: #ffffff;
  border-radius: 7px 0 0 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}
.form__input {
  font-size: 1.5rem;
  font-family: inherit;
  color: inherit;
  padding: 1.5rem 1.5rem 1.5rem 7rem;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  display: block;
  transition: all 0.3s;
  border: 1px solid #bcd871;
  border-radius: 7px;
  min-width: 100%;
}
.form__input:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(34, 34, 34, 0.1);
  border-bottom: 3px solid #bcd871;
}
.form__input:focus:invalid {
  border-bottom: 3px solid #97ad5a;
}
.form__input::-webkit-input-placeholder {
  color: #707070;
}
.form__label {
  font-size: 1.2rem;
  font-weight: 300;
  margin-left: 2rem;
  margin-top: 0.7rem;
  display: block;
  transition: all 0.3s;
  position: absolute;
  top: 5rem;
}
.form__input:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4rem);
}

.slide {
  width: 100%;
}

.slick-prev {
  left: 10px !important;
  z-index: 2;
}

.slick-next {
  right: 10px !important;
  z-index: 2;
}

.slick-next:before {
  color: #bcd871 !important;
}

.slick-prev:before {
  color: #bcd871 !important;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 2.5rem 3rem;
  margin: 0 auto;
}
.header__brand-logo {
  width: 165px;
}

.nav__checkbox {
  display: none;
}
.nav__button {
  background-color: #fafafa;
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  z-index: 2000;
  text-align: center;
  cursor: pointer;
}
.nav__background {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  position: absolute;
  top: 1.25rem;
  right: 1.5rem;
  background-image: radial-gradient(#bcd871, #cfef7f);
  z-index: 1000;
  transition: transform 0.5s cubic-bezier(0.83, 0, 0.17, 1);
}
.nav__nav {
  height: 100vh;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1500;
  display: none;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.56, 0.16, 0.35, 1.31);
}
.nav__list {
  list-style: none;
  text-align: center;
  width: 100%;
}
.nav__item {
  margin: 1rem;
}
.nav__link:link, .nav__link:visited {
  display: inline-block;
  font-size: 3rem;
  font-weight: 300;
  padding: 1rem 2rem;
  color: #222222;
  text-decoration: none;
  text-transform: uppercase;
  background-size: 225%;
  transition: all 0.4s;
}
.nav__link:link span, .nav__link:visited span {
  margin-right: 1.5rem;
  display: inline-block;
}
.nav__link:hover, .nav__link:active {
  background-position: 100%;
  color: #ffffff;
  transform: translateX(1rem);
}
.nav__checkbox:checked ~ .nav__background {
  transform: scale(100);
}
.nav__checkbox:checked ~ .nav__nav {
  width: 100%;
  display: unset;
  opacity: 1;
}
.nav__icon {
  position: relative;
  margin-top: 2.5rem;
}
.nav__icon, .nav__icon::after, .nav__icon::before {
  width: 3rem;
  height: 2px;
  background-color: #191919;
  display: inline-block;
}
.nav__icon::after, .nav__icon::before {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.2s;
}
.nav__icon::after {
  top: 0.8rem;
}
.nav__icon::before {
  top: -0.8rem;
}
.nav__button:hover .nav__icon::before {
  top: -1rem;
}
.nav__button:hover .nav__icon::after {
  top: 1rem;
}
.nav__checkbox:checked + .nav__button .nav__icon {
  background-color: transparent;
}
.nav__checkbox:checked + .nav__button .nav__icon::before {
  transform: rotate(135deg);
  top: 0;
}
.nav__checkbox:checked + .nav__button .nav__icon::after {
  transform: rotate(-135deg);
  top: 0;
}

.nav__link--white {
  font-size: 1.3rem;
  color: rgba(255, 255, 255, 0.65);
  text-decoration: none;
}

.thankyou {
  margin: 5rem 3rem;
}

.content {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 599px) {
  .content {
    width: 60rem;
    margin: 0 auto;
  }
}
.contact {
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 3rem;
}
.contact > h1,
.contact h3 {
  margin-top: 3rem;
}
.contact__contacts > h2 {
  margin-top: 5rem;
}
.contact__contacts > h2:first-of-type {
  margin-top: 7.5rem;
}

.home {
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 3rem;
}

.products {
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 3rem;
}
.products > h1,
.products p {
  margin-top: 3rem;
}

