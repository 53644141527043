.content {
  // display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 599px) {
  .content {
    width: 60rem;
    margin: 0 auto;
  }
}
