.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 7px;
  padding: 5rem 0;
  margin-top: 7.5rem;
  margin-bottom: 2.5rem;
  background-color: $color-white;
  box-sizing: border-box;
  @include box-shadow;

  &__group {
    display: flex;
    flex-direction: row;
    width: 90%;
    position: relative;

    & > button {
      margin: 5rem auto;
    }
  }

  &__group:not(:last-child) {
    margin-bottom: 3.5rem;
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 5.5rem;
    height: 100%;
    background-color: $color-primary;
    color: $color-white;
    border-radius: 7px 0 0 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
  }

  &__input {
    font-size: 1.5rem;
    font-family: inherit;
    color: inherit;
    padding: 1.5rem 1.5rem 1.5rem 7rem;
    background-color: rgba($color-white, 0.5);
    width: 100%;
    display: block;
    transition: all 0.3s;
    border: 1px solid $color-primary;
    border-radius: 7px;
    min-width: 100%;

    &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, 0.1);
      border-bottom: 3px solid $color-primary;
    }
    &:focus:invalid {
      border-bottom: 3px solid $color-secondary;
    }

    &::-webkit-input-placeholder {
      color: $color-gray;
    }
  }

  &__label {
    font-size: 1.2rem;
    font-weight: 300;
    margin-left: 2rem;
    margin-top: 0.7rem;
    display: block;
    transition: all 0.3s;
    position: absolute;
    top: 5rem;
  }

  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem);
  }
}
