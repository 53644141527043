// INSERT ADOBE FONTS
@import url("https://use.typekit.net/mfp3taz.css");

/* CSS RESET */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /* 10px / 16px = 62.5% => 1rem = 10px */
}

body {
  font-family: "Rival-Sans", serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.6;
  background-color: $color-background;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "lato", sans-serif;
  font-style: normal;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
.hidden {
  display: none;
}
a {
  text-decoration: none;
  color: inherit;
}
