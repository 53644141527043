.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 4.5rem 0 7.5rem 0;

  &__btn {
    background-color: $color-primary;
    color: $color-text-dark;
    padding: 2rem 5rem;
    border-radius: 0.5rem;
    border: 1px solid $color-secondary;
    box-shadow: 3px 3px 7px rgba($color-text-dark, 0.15);
    font-size: 2rem;
    font-weight: 400;
  }
}
