@keyframes slowFade {
  5% {
    opacity: 1;
    transform: scale(1) rotate(0);
  }
  100% {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    left: 300px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    left: 0;
    opacity: 1;
  }
  to {
    left: -500px;
    opacity: 0;
  }
}
