.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 2.5rem 3rem;
  margin: 0 auto;

  &__brand {
    &-logo {
      width: 165px;
    }
  }
}
