.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  margin-top: 7.5rem;
  padding: 3rem;
  max-width: 100%;
  background-color: #ffffff;
  @include box-shadow;

  // &:not(:first-of-type) {
  //   margin-top: 3rem;
  // }

  &__image {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    // border-radius: 5px 5px 0 0;
    border-bottom: 5px solid $color-primary;
  }

  &__image img {
    width: 100%;
    margin-bottom: 3rem;
    // border-radius: 5px 5px 0 0;
  }

  &__headline {
    margin: 2rem 0;
  }

  &__text-button {
    align-self: center;
    margin-top: 5rem;
    margin-bottom: 2rem;
  }

  &__link {
    // padding: 2rem 3rem;
    border-radius: 5px;
    background-color: #ffffff;
    text-decoration: underline;
    color: $color-secondary;
    font-size: 1.8rem;
    transition: all 1s ease;
  }

  &__link:hover {
    background-color: $color-tertiary;
    color: $color-secondary;
  }
}
