.nav {
  &__checkbox {
    display: none;
  }

  &__button {
    background-color: $color-background;
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    z-index: 2000;
    // box-shadow: 0 1rem 1rem rgba($color-fill-dark, 0.1);
    text-align: center;
    cursor: pointer;
  }

  &__background {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    position: absolute;
    top: 1.25rem;
    right: 1.5rem;
    background-image: radial-gradient($color-primary, rgba($color-tertiary, 1));
    z-index: 1000;
    transition: transform 0.5s cubic-bezier(0.83, 0, 0.17, 1);
  }

  &__nav {
    height: 100vh;
    width: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1500;
    display: none;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.56, 0.16, 0.35, 1.31);
  }

  &__list {
    // @include center-vert-horz;
    list-style: none;
    text-align: center;
    width: 100%;
  }

  &__item {
    margin: 1rem;
  }

  &__link {
    &:link,
    &:visited {
      display: inline-block;
      font-size: 3rem;
      font-weight: 300;
      padding: 1rem 2rem;
      color: $color-text-dark;
      text-decoration: none;
      text-transform: uppercase;
      background-size: 225%;
      transition: all 0.4s;

      span {
        margin-right: 1.5rem;
        display: inline-block;
      }
    }

    &:hover,
    &:active {
      background-position: 100%;
      color: $color-text-light;
      transform: translateX(1rem);
    }
  }

  // FUNCTIONALITY
  &__checkbox:checked ~ &__background {
    transform: scale(100);
  }

  &__checkbox:checked ~ &__nav {
    width: 100%;
    display: unset;
    opacity: 1;
  }

  // ICON
  &__icon {
    position: relative;
    margin-top: 2.5rem;

    &,
    &::after,
    &::before {
      width: 3rem;
      height: 2px;
      background-color: $color-fill-dark;
      display: inline-block;
    }

    &::after,
    &::before {
      content: "";
      position: absolute;
      left: 0;
      transition: all 0.2s;
    }

    &::after {
      top: 0.8rem;
    }

    &::before {
      top: -0.8rem;
    }
  }

  // BUTTON

  &__button:hover &__icon::before {
    top: -1rem;
  }

  &__button:hover &__icon::after {
    top: 1rem;
  }

  &__checkbox:checked + &__button &__icon {
    background-color: transparent;
  }

  &__checkbox:checked + &__button &__icon::before {
    transform: rotate(135deg);
    top: 0;
  }
  &__checkbox:checked + &__button &__icon::after {
    transform: rotate(-135deg);
    top: 0;
  }
}

// FOOTER NAVIGATION
.nav__link--white {
  font-size: 1.3rem;
  color: rgba($color-white, 0.65);
  text-decoration: none;
}
